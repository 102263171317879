<template>
  <b-form-group
    label="Konum"
    label-for="position"
  >
    <validation-provider
      #default="{ errors }"
      name="Konum"
      rules="required"
    >
      <v-select
        v-model="dataItem.position"
        :options="positions"
        label="title"
        :reduce="item => item.value"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'PostionSelect',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
      positions: [
        { title: 'Sağ', value: 'left' },
        { title: 'Sol', value: 'right' },
        { title: 'Üst', value: 'top' },
        { title: 'Alt', value: 'bottom' },
        { title: 'Güvenilir Siteler Üst', value: 'links_top' },
        { title: 'Güvenilir Siteler Alt', value: 'links_bottom' },
        { title: 'Etkinlikler Üst', value: 'events_top' },
        { title: 'Etkinlikler Alt', value: 'events_bottom' },
      ],
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['banners/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
