<template>
  <div>
    <b-card>
      <title-text />
      <url-text />
      <position-select />
      <status-select />
    </b-card>
    <image-card />
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import TitleText from '@/views/Admin/Banners/elements/TitleText.vue'
import UrlText from '@/views/Admin/Banners/elements/UrlText.vue'
import PositionSelect from '@/views/Admin/Banners/elements/PositionSelect.vue'
import StatusSelect from '@/views/Admin/Banners/elements/StatusSelect.vue'
import ImageCard from '@/views/Admin/Banners/elements/ImageCard.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    TitleText,
    UrlText,
    PositionSelect,
    StatusSelect,
    ImageCard,
  },
}
</script>
