<template>
  <b-form-group
    label="Bağlantı"
    label-for="url"
  >
    <validation-provider
      #default="{ errors }"
      name="Bağlantı"
      rules="required|url"
    >
      <b-form-input
        id="url"
        v-model="dataItem.url"
        placeholder="Bağlantı"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'UrlText',
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  computed: {
    dataItem() {
      return this.$store.getters['banners/dataItem']
    },
  },
}
</script>
